import * as Sentry from "@sentry/gatsby"
import { Dedupe as DedupeIntegration } from "@sentry/integrations"

Sentry.init({
  dsn: "https://8b66c6935e564cf8a0d6cab7dbbaed3f@o4504448172949504.ingest.sentry.io/4504456576040960",
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
  defaultIntegrations: false,
  integrations: [new DedupeIntegration()],
})
